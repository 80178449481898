* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  /* background-color:#490f05; */
}

.clear  {
  color: #ffffff00;
}