* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: inter;
    font-weight: normal;
    background: rgb(245, 245, 245);
  }
  
  h1 {
    text-align: center;
    margin-top: 60px;
    font-size: 35px;
  }
  
  p {
    text-align: center;
    margin: 15px auto 0px auto;
    font-size: 16px;
    width: 80%;
  }
  .calender-doc {
    background-color: white;
    color: black;
  }
  .contact-form {
    margin: 35px auto 0px auto;
    width: 80%;
  }
  
  .advertisement-form label {
    font-size: 20px;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .advertisement-form input {
    padding: 12px 20px;
    outline: none;
    border: none;
    border-bottom: 2px solid #ccc;
    background: rgb(255, 255, 255);
    font-size: 18px;
    border-radius: 3px;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .advertisement-form input:focus {
    border-bottom: 2px solid #c7c2bb;
  }
  
  .advertisement-form textarea {
    padding: 12px 20px;
    outline: none;
    border: none;
    border-bottom: 2px solid #ccc;
    background: rgb(255, 255, 255);
    font-size: 18px;
    border-radius: 3px;
    width: 100%;
    font-family: inter;
    font-weight: normal;
  }
  
  .advertisement-form textarea:focus {
    border-bottom: 2px solid #c7c2bb;
  }
  
  .advertisement-form button {
    font-size: 19px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    border: none;
    padding: 12px 20px;
    background-color: #ffffff;
    color: #490f05;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border-bottom: 3px solid #c7c2bb;
  }
  
  .advertisement-form button:hover {
    background-color: #994132;
    border-bottom: 3px solid #994132;
  }
  
  @media all and (min-width: 1000px) {
    .advertisement-form {
      width: 40%;
    }
  }
  
  @media all and (min-width: 1080px) {
    .advertisement-form {
      width: 30%;
    }
  }

  .fa-times-red-center  {
    color: #ffffff;
    font-size: 1.5rem;
    background-color: #490f05;
  }

  .fa-medium-red-center  {
    color: #ffffff;
    font-size: 2rem;
    background-color: transparent;
    text-align: center;
  }

  .fa-small-red-center {
    color: #ffffff;
    font-size: 3rem;
    background-color: #c7c2bb;
    padding: 1cm;
    text-align: center;
  }

  .fa-medium-warning  {
    color: #ffffff;
    font-size: 1rem;
    background-color: transparent;
    text-align: le;
    text-decoration: underline;
  }

  .fa-advertisementTitle  {
    color: #ffffff;
    font-size: 2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: #c7c2bb;
    text-align: center;
    background-color: #241f0f;
    padding-top: 5mm;
  }

  .fa-description  {
    color: #ffffff;
    font-size: 1rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: #c7c2bb;
    text-align: left;
    align-content: center;
    align-items: center;
    padding-top: 5mm;
    background-color: #241f0f;
    justify-content: center;
    flex-flow: row wrap;
    display: flex;

    }

    .background-advertisement {
      background-color: transparent;
    }

    .background-white {
      background-color: white;
    }
